.boxContextLink {
    flex: 1;
}

.seeMore {
    padding-top: 20px;
}

.class-paper {
    padding: 16px !important;
    display: 'flex' !important;
    overflow: 'auto' !important;
    flexDirection: 'column' !important;
}

.modal-confirm-size {
    position: absolute;
    width: 30%;
    height: 20%;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;

    padding: 20px;

    background-color: white;

    transform: translate(-50%, -50%);
}

@import url('https://use.fontawesome.com/releases/v5.12.0/css/all.css');

.modal-size {
    position: absolute;
    width: 50%;
    height: 90%;

    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;

    padding: 20px;

    background-color: white;

    transform: translate(-50%, -50%);
}

.fullSizeList {
    width: 100%;
}